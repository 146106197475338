#home{
    position: relative;
    
}


.app__wrapper{
    padding:0;
}


.app__header {
    flex:1;
    width: 100%;
    height: 100%;
    flex-direction: row;

    padding: 6rem 2rem 0;

    @media screen and (min-width: 2000px) {
        padding-top: 8rem;
    }

    @media screen and (max-width: 1200px) {
        flex-direction: column;
    }

    @media screen and (max-width: 450px) {
        padding: 6rem 1rem 2rem;;
    }
}


.app__header-info{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;

    @media screen and (max-width:2000px)  {
        width: 100%;
        margin-right: 0rem;
        
    }
}


.app__header-badge{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    .badge-cmp, .tag-cmp{
        padding: 1rem 2rem;
        border: var(--white-color);
        border-radius: 15px;
        flex-direction: row;
        width: auto;

        box-shadow: 0 0 20px rgba(0,0,0,0.1);
    }

    .tag-cmp{
        flex-direction: column;
        margin-top: 3rem;

        p{
            width: 100%;
            text-transform: uppercase;
            text-align: right;
        }
    }

    span{
        font-size:2.5rem ;

        @media screen and (min-width: 2000px) {
            font-size: 5rem;
            
        }
    }

    @media screen and (max-width: 1200px) {
        justify-content: flex-start;
        align-items: flex-start;
        
    }
}


.app__header-circles {
    flex: 0.4;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    height: 100%;
    margin-left: 1rem;

    div{
        width: 100px;
        height: 100px;
        border-radius: 50%;

        background: var(--white-color);
        box-shadow: 0 0 20px rgba(0,0,0,0.1);

        img{
            width: 60%;
            height: 60%;
        }
    }

    div:nth-child(1){
        width: 100px;
        height: 100px;
    }

    div:nth-child(2){
        margin: 1.75rem;
        width: 150px;
        height: 150px;
    }
    div:nth-child(3){
        width: 70px;
        height: 70px;
    }

    div:nth-child(4){
        width: 100px;
        height: 100px;
        margin: 1.75rem;
    }


    @media screen and (min-width:2000px) {

        div:nth-child(1){
            width: 400px;
            height: 400px;
        }
    
        div:nth-child(2){
            margin: 1.75rem;
            width: 450x;
            height: 450px;
        }
        div:nth-child(3){
            width: 240px;
            height: 240px;
        }
    
        div:nth-child(4){
            width: 400px;
            height: 400px;
            margin: 1.75rem;
        }
        
    }

    @media screen and (max-width:1200px){
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left:0 ;

        div{
            margin: 1rem;
        }
        
    }


}


.app__header-img{
    flex:0.8;
    height: 100%;

    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;

    img{
        width: 100%;
        object-fit: contain;
        z-index: 1;
        
    }


    .overlay_circle{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        width: 100%;
        height: 90%;

    }

    @media screen and (max-width:1200px){
        margin: 2rem 0;
        
    }

}


.nameContainer {
    width: 100%;
    height: 80vh;
    background: #232323;
  
    display: flex;
    justify-content: center;
    align-items: center;
  
    .nameBox {
      width: 600px;
      height: 250px;
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: column;
  
      .nameTitle {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        height: 50px;
  
        .nameBlock {
          width: 0%;
          height: inherit;
          background: #ffb510;
          position: absolute;
          animation: mainBlock 2s cubic-bezier(.74, .06, .4, .92) forwards;
          display: flex;
        }
  
        h1 {
          font-family: 'Poppins';
          color: #fff;
          font-size: 48px;
          -webkit-animation: mainFadeIn 2s forwards;
          -o-animation: mainqFadeIn 2s forwards;
          animation: mainFadeIn 2s forwards;
          animation-delay: 1.6s;
          opacity: 0;
          display: flex;
          align-items: baseline;
          position: relative;
  
          span {
            width:0px;
            height: 0px;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
  
            background: #ffb510;
            -webkit-animation: load 0.6s cubic-bezier(.74, .06, .4, .92) forwards;
            animation: popIn 0.8s cubic-bezier(.74, .06, .4, .92) forwards;
            animation-delay: 2s;
            margin-left: 5px;
            margin-top: -10px;
            position: absolute;
            bottom: 13px;
            right: -12px;
  
          }
        }
      }
  
      .nameRole {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        height: 30px;
        margin-top: 2px;
  
        .nameBlock {
          width: 0%;
          height: inherit;
          background: #e91e63;
          position: absolute;
          animation: secBlock 2s cubic-bezier(.74, .06, .4, .92) forwards;
          animation-delay: 2s;
          display: flex;
        }
  
        p {
          animation: secFadeIn 2s forwards;
          animation-delay: 3.2s;
          opacity: 0;
           font-weight: 400;
          font-family: 'Lato';
          color: #ffffff;
          font-size: 18px;
          text-transform: uppercase;
          letter-spacing: 5px;
        }
      }
    }
  }
  
  
  
  
  
  @keyframes mainBlock {
    0% {
      width: 0%;
      left: 0;
  
    }
    50% {
      width: 100%;
      left: 0;
  
    }
    100% {
      width: 0;
      left: 100%;
    }
  }
  
  @keyframes secBlock {
    0% {
      width: 0%;
      left: 0;
  
    }
    50% {
      width: 100%;
      left: 0;
  
    }
    100% {
      width: 0;
      left: 100%;
    }
  }
  
  @keyframes mainFadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  
  @keyframes popIn {
    0% {
      width: 0px;
      height: 0px;
      background: #e9d856;
      border: 0px solid #ddd;
      opacity: 0;
    }
    50% {
      width: 10px;
      height: 10px;
      background: #e9d856;
      opacity: 1;
      bottom: 45px;
    }
     65% {
        width: 7px;
      height: 7px;
        bottom: 0px;
        width: 15px
     }
     80% {
        width: 10px;
      height: 10px;
        bottom: 20px
     }
    100% {
      width: 7px;
      height: 7px;
      background: #e9d856;
      border: 0px solid #222;
      bottom: 13px;
  
    }
  }
  
  @keyframes secFadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
  }
  
  
  
  
  footer {
    width: 350px;
    height: 80px;
    background: #ffb510;
    position: absolute;
    right: 0;
    bottom: -80px;
    display: flex;
    justify-content: center;
    align-items: center;
     animation: top 0.8s forwards;
     animation-delay: 4s;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: #232323;
      font-family: 'Poppins';
  
      i {
        margin-right: 25px;
        font-size: 22px;
        color: #232323;
        animation: icon 2s forwards;
        animation-delay: 4s;
        opacity: 0;
      }
    }
  }
  
  @keyframes top {
    0% {
      opacity: 0;
       bottom: -80px
    }
    100% {
      opacity: 1;
       bottom: 0px
  
    }
  }
  
  @keyframes icon {
    0% {
      opacity: 0;
       transform: scale(0.0);
    }
     50% {
        opacity: 1;
       transform: scale(1.3) rotate(-02deg);
     }
    100% {
      opacity: 1;
       bottom: 0px;
    }
  }


